import { createRouter, createWebHashHistory } from "vue-router";
import layout from "../layout/index.vue";
import Login from "../views/Login/Login.vue";
import PhoneVerify from "../views/Login/PhoneVerify.vue";

const routes = [
  {
    path: "/Login", //用户管理
    name: "Login",
    component: Login,
  },
  {
    path: "/PhoneVerify",
    name: "PhoneVerify",
    component: PhoneVerify,
  },
  {
    path: "/",
    name: "Layout",
    component: layout,
    redirect: "/UesrAdmin",
    children: [
      // 主数据管理
      {
        path: "/UesrAdmin", //用户管理
        name: "UesrAdmin",
        component: () => import("@/views/Data/UesrAdmin.vue"),
      },
      {
        path: "/ShopAdmin", //商家管理
        name: "ShopAdmin",
        component: () => import("@/views/Data/ShopAdmin.vue"),
      },
      {
        path: "/WorkAdmin", //工作人员管理
        name: "WorkAdmin",
        component: () => import("@/views/Data/WorkAdmin.vue"),
      },
      {
        path: "/Admin", //管理员管理
        name: "Admin",
        component: () => import("@/views/Data/Admin.vue"),
      },
      // 问题反馈
      {
        path: "/Userfeedback", //居民反馈
        name: "Userfeedback",
        component: () => import("@/views/Feedback/Userfeedback.vue"),
      },
      {
        path: "/Shopfeedback", //商家反馈
        name: "Shopfeedback",
        component: () => import("@/views/Feedback/Shopfeedback.vue"),
      },
      // 任务管理
      {
        path: "/Task", //任务管理
        name: "Task",
        component: () => import("@/views/Task/Task.vue"),
      },
      {
        path: "/Integral", //积分商城
        name: "Integral",
        component: () => import("@/views/Task/Integral.vue"),
      },
      // {
      //   path: "/IntegralList", //积分商对还记录
      //   name: "IntegralList",
      //   component: () => import("@/views/Task/IntegralList.vue"),
      // },
      // 物业管理
      {
        path: "/Property", //物业费管理
        name: "Property",
        component: () => import("@/views/Property/Property.vue"),
      },
      {
        path: "/troubleshooting", //物业保修
        name: "troubleshooting",
        component: () => import("@/views/troubleshooting/troubleshooting.vue"),
      },
      {
        //troubleshooting
        path: "/Car", //车辆管理
        name: "Car",
        component: () => import("@/views/Property/Car.vue"),
      },
      {
        path: "/Door", //门禁录入审核
        name: "Door",
        component: () => import("@/views/Property/Door.vue"),
      },
      {
        path: "/Money", //物业收入公示
        name: "Money",
        component: () => import("@/views/Property/Money.vue"),
      },
      // 便民服务管理
      {
        path: "/New", //新闻管理
        name: "New",
        component: () => import("@/views/Convenience/New.vue"),
      },
      {
        //风采展示
        path: "/Show",
        name: "Show",
        component: () => import("@/views/Convenience/personShow.vue"),
      },
      {
        //菜园
        path: "/ShareGarden",
        name: "ShareGarden",
        component: () => import("@/views/Convenience/ShareGarden.vue"),
      },
      {
        //圈子
        path: "/CircleAdmin",
        name: "CircleAdmin",
        component: () => import("@/views/Convenience/CircleAdmin.vue"),
      },
      {
        //团相应管理
        path: "/GroupFactor",
        name: "GroupFactor",
        component: () => import("@/views/Convenience/GroupFactor.vue"),
      },
      {
        path: "/Activity", //活动管理
        name: "Activity",
        component: () => import("@/views/Convenience/Activity.vue"),
      },
      {
        path: "/HelpEach", //活动管理
        name: "HelpEach",
        component: () => import("@/views/Task/HelpEach.vue"),
      },
      {
        path: "/GridService", //网格服务
        name: "GridService",
        component: () => import("@/views/Convenience/gridService.vue"),
      },
      {
        path: "/Rent", //租房管理
        name: "Rent",
        component: () => import("@/views/Convenience/Rent.vue"),
      },
      {
        path: "/Appointment", //上门预约
        name: "Appointment",
        component: () => import("@/views/Convenience/Appointment.vue"),
      },
      {
        path: "/Bianming", //快捷便民
        name: "Bianming",
        component: () => import("@/views/Convenience/Bianming.vue"),
      },
      {
        path: "/Settle", //商家入驻管理
        name: "Settle",
        component: () => import("@/views/Convenience/Settle.vue"),
      },
      {
        path: "/GoodsType", //商家类别管理
        name: "GoodsType",
        component: () => import("@/views/Convenience/GoodsType.vue"),
      },
      {
        path: "/GoodsList", //商家商品管理
        name: "GoodsList",
        component: () => import("@/views/Convenience/GoodsList.vue"),
      },
      {
        path: "/VoucherType", //代金券管理
        name: "VoucherType",
        component: () => import("@/views/Voucher/VoucherType.vue"),
      },
      {
        path: "/VoucherHunt", //代金券寻宝管理管理
        name: "VoucherHunt",
        component: () => import("@/views/Voucher/VoucherHunt.vue"),
      },
      {
        path: "/VoucherVideo", //视频管理
        name: "VoucherVideo",
        component: () => import("@/views/Voucher/VoucherVideo.vue"),
      },
      {
        path: "/VoucherListUser", //代金券商家记录
        name: "VoucherListUser",
        component: () => import("@/views/Voucher/VoucherListUser.vue"),
      },
      {
        path: "/VoucherListShop", //代金券商家记录
        name: "VoucherListShop",
        component: () => import("@/views/Voucher/VoucherListShop.vue"),
      },
      {
        path: "/Employment", //就业岗位管理
        name: "Employment",
        component: () => import("@/views/Convenience/Employment.vue"),
      },
      {
        path: "/Law", //法律服务管理
        name: "Law",
        component: () => import("@/views/Convenience/Law.vue"),
      },
      {
        path: "/Government", //政务管理
        name: "Government",
        component: () => import("@/views/Convenience/Government.vue"),
      },
      {
        //公益创投
        path: "/VenturePhilanthropy",
        name: "VenturePhilanthropy",
        component: () => import("@/views/Convenience/VenturePhilanthropy.vue"),
      },
      {
        //兴美食
        path: "/FineFood",
        name: "FineFood",
        component: () => import("@/views/Convenience/FineFood.vue"),
      },
      {
        path: "/GoverMent", //政务管理
        name: "GoverMent",
        component: () => import("@/views/Convenience/GoverMent.vue"),
      },
      // 人员投票
      {
        path: "/vote",
        name: "vote",
        component: () => import("@/views/Convenience/vote.vue"),
      },
      // 人员投票新增
      {
        path: "/voteAdd",
        name: "voteAdd",
        component: () => import("@/views/Convenience/voteAdd.vue"),
      },
      {
        path: "/ThingAgree", //事务投票
        name: "ThingAgree",
        component: () => import("@/views/Convenience/ThingAgree.vue"),
      },
      // 系统设置
      {
        path: "/Banner", //轮播图设置
        name: "Banner",
        component: () => import("@/views/Setting/Banner.vue"),
      },
      {
        path: "/Community", //轮播图设置
        name: "Community",
        component: () => import("@/views/Setting/Community.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const jti = sessionStorage.getItem("jti");
  if (to.name == "PhoneVerify") {
    jti || to.name === "PhoneVerify"
      ? next()
      : next({
          name: "PhoneVerify",
        });
  } else {
    jti || to.name === "Login"
      ? next()
      : next({
          name: "Login",
        });
  }
});
export default router;
