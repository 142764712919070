/*
 * @ModuleName: zcz
 * @Date: 2021-08-26、2021-08-27
 */

import { get, post, put, dele, exl } from "@/http/api";

/**----------------------------系统设置---------------------------- */

/**
 * 轮播图获取
 * @param { }params
 */
export const bannerGetAll = (params) =>
  get(`/backed/system/banner/getAll`, params);

/**
 * 轮播图新增
 * @param { }params
 */
export const bannerAdd = (params) => post(`/backed/system/banner/add`, params);

/**
 * 轮播图修改
 * @param { }params
 */
export const bannerUpdate = (params) =>
  put(`/backed/system/banner/update`, params);

/**
 * 轮播图删除
 * @param { }params
 */
export const bannerDel = (params) =>
  dele(`/backed/system/banner/del/${params}`);

/**
 * 关于我们获取
 * @param { }params
 */
export const aboutGet = (params) => get(`/backed/system/about/get`, params);

/**
 * 关于我们修改
 * @param { }params
 */
export const aboutUpdate = (params) =>
  put(`/backed/system/about/update`, params);

/**
 * 小程序统计
 * @param { }params
 */
export const getStatistics = (params) =>
  get(`/backed/admin/loginData/statistics`, params);

/**
 * 小区列表
 * @param { }params
 */
export const getCommunity = (params) => get(`/backed/community/List`, params);

// 小区新增
export const addCommunity = (params) => post(`/backed/community/add`, params);
//小区删除
export const delCommunity = (params) =>
  dele(`/backed/community/delete`, params);

export const UpdateCommunity = (params) =>
  put(`/backed/community/update`, params);
