const menu = [
  {
    icon: "1",
    title: "主数据管理",
    path: "/data",
    name: "data",
    children: [
      {
        path: "/UesrAdmin",
        name: "UesrAdmin",
        title: "居民管理",
        children: [],
      },
      {
        path: "/ShopAdmin",
        name: "ShopAdmin",
        title: "商家管理",
        children: [],
      },
      {
        path: "/WorkAdmin",
        name: "WorkAdmin",
        title: "工作人员管理",
        children: [],
      },
      {
        path: "/Admin",
        name: "Admin",
        title: "管理员管理",
        children: [],
      },
      {
        path: "/GridService", //网格服务
        name: "GridService",
        title: "网格服务",
        children: [],
      },
    ],
  },
  {
    icon: "2",
    title: "问题反馈",
    path: "/Feedback",
    name: "Feedback",
    children: [
      {
        path: "/Userfeedback",
        name: "Userfeedback",
        title: "居民反馈",
        children: [],
      },
      {
        path: "/Shopfeedback",
        name: "Shopfeedback",
        title: "商家反馈",
        children: [],
      },
    ],
  },
  {
    icon: "3",
    title: "任务管理",
    path: "/TaskOne",
    name: "TaskOne",
    children: [
      {
        path: "/Task",
        name: "Task",
        title: "任务管理",
        children: [],
      },
      {
        path: "/Integral",
        name: "Integral",
        title: "积分商城",
        children: [],
      },
      {
        path: "/HelpEach",
        name: "HelpEach",
        title: "爱心互助",
        children: [],
      },
      // {
      //   path: "/IntegralList",
      //   name: "IntegralList",
      //   title: "积分商品兑换记录",
      //   children: [],
      // },
    ],
  },
  {
    icon: "4",
    title: "物业管理",
    path: "/PropertyOne",
    name: "PropertyOne",
    children: [
      {
        path: "/Property",
        name: "Property",
        title: "物业费管理",
        children: [],
      },
      {
        path: "/troubleshooting",
        name: "troubleshooting",
        title: "物业报修",
        children: [],
      },
      {
        path: "/Car",
        name: "Car",
        title: "车辆管理",
        children: [],
      },
      {
        path: "/Door",
        name: "Door",
        title: "门禁录入审核",
        children: [],
      },
      {
        path: "/Money",
        name: "Money",
        title: "物业收入公示",
        children: [],
      },
    ],
  },
  {
    icon: "5",
    title: "便民服务管理",
    path: "/Convenience",
    name: "Convenience",
    children: [
      {
        path: "/New", //新闻管理
        name: "New",
        title: "新闻管理",
        children: [],
      },
      {
        path: "/Activity", //活动管理
        name: "Activity",
        title: "活动管理",
        children: [],
      },
      {
        path: "/Rent", //租房管理
        name: "Rent",
        title: "租房管理",
        children: [],
      },
      {
        path: "/Show", //风采展示
        name: "Show",
        title: "风采展示",
        children: [],
      },
      {
        path: "/ShareGarden", //共享菜园
        name: "ShareGarden",
        title: "共享菜园",
        children: [],
      },
      {
        path: "/CircleAdmin", //晒晒圈子
        name: "CircleAdmin",
        title: "晒晒圈子",
        children: [],
      },
      {
        path: "/GroupFactor", //团相应管理
        name: "GroupFactor",
        title: "团相因",
        children: [],
      },
      {
        path: "/Appointment", //上门预约
        name: "Appointment",
        title: "上门预约",
        children: [],
      },
      {
        path: "/Bianming", //家电维修
        name: "Bianming",
        title: "家电维修",
        children: [],
      },
      // {
      //     path: '/Settle', //商家入驻管理
      //     name: 'Settle',
      //     title: '商家入驻管理',
      //     children: []
      // },
      {
        path: "/GoodsType", //商品类别管理
        name: "GoodsType",
        title: "商品类别管理",
        children: [],
      },
      {
        path: "/GoodsList", //商家商品管理
        name: "GoodsList",
        title: "商家商品管理",
        children: [],
      },
      {
        path: "/Employment", //就业岗位管理
        name: "Employment",
        title: "就业岗位管理",
        children: [],
      },
      {
        path: "/Law", //法律服务管理
        name: "Law",
        title: "法律服务管理",
        children: [],
      },
      {
        path: "/VenturePhilanthropy",
        name: "VenturePhilanthropy",
        title: "公益创投",
        children: [],
      },
      {
        //兴美食
        path: "/FineFood",
        name: "FineFood",
        title: "兴美食",
        children: [],
      },
      {
        path: "/GoverMent",
        name: "GoverMent",
        title: "政务管理",
        children: [],
      },
      // {
      //   path: "/Government", //政务管理
      //   name: "Government",
      //   title: "政务管理",
      //   children: [],
      // },
      {
        // 人员投票
        path: "/vote",
        name: "vote",
        title: "人员投票",
        children: [],
      },
      {
        path: "/ThingAgree", //事务投票
        name: "ThingAgree",
        title: "事务投票",
        children: [],
      },
    ],
  },
  {
    icon: "7",
    title: "代金券",
    path: "/Voucher",
    name: "Voucher",
    children: [
      {
        path: "/VoucherHunt", //寻宝记管理
        name: "VoucherHunt",
        title: "寻宝记管理",
        children: [],
      },
      {
        path: "/VoucherVideo", //视频管理
        name: "VoucherVideo",
        title: "兔出我的风采",
        children: [],
      },
      {
        path: "/VoucherType", //代金券管理
        name: "VoucherType",
        title: "代金券管理",
        children: [],
      },
      {
        path: "/VoucherListUser", //代金券居民记录
        name: "VoucherListUser",
        title: "居民领取记录",
        children: [],
      },
      {
        path: "/VoucherListShop", //代金券商家记录
        name: "VoucherListShop",
        title: "商家兑换记录",
        children: [],
      },
    ],
  },
  {
    icon: "6",
    title: "系统设置",
    path: "/Setting",
    name: "Setting",
    children: [
      {
        path: "/Banner", //轮播图
        name: "Banner",
        title: "轮播图",
        children: [],
      },
      {
        path: "/Community", //
        name: "Community",
        title: "小区管理",
        children: [],
      },
    ],
  },
];

export default menu;
