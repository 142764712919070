import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, ref } from "vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
export default {
  name: 'App',

  setup(__props) {
    const language = ref("zh-cn");
    const locale = computed(() => language.value === "zh-cn" ? zhCn : en);

    const toggle = () => {
      language.value = language.value === "zh-cn" ? "en" : "zh-cn";
    };

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_el_config_provider = _resolveComponent("el-config-provider");

      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(locale)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }

};