import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import { reactive, watch } from "vue"; // 导航栏数据

import menu from "@/utils/menu"; // 组件

import Menu from "./Menu.vue"; // 路由添砖

import { useRouter } from "vue-router";
export default {
  name: 'Aside',

  setup(__props) {
    const router = useRouter(); // 导航栏

    const menuObj = reactive({
      active: router.currentRoute.value.path,
      arr: menu
    });

    const handleSelect = (index, indexPath, item) => {
      router.push({
        path: index
      });
      console.log(index);
      console.log(indexPath);
      console.log(item);
    }; // 监听路由


    watch(() => router.currentRoute.value.path, (newValue, oldValue) => {
      menuObj.active = newValue;
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_menu = _resolveComponent("el-menu");

      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": menuObj.active,
        onSelect: handleSelect,
        "vue-router": true,
        "unique-opened": true
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuObj.arr, item => {
          return _openBlock(), _createBlock(Menu, {
            key: item.path,
            route: item
          }, null, 8, ["route"]);
        }), 128))]),
        _: 1
      }, 8, ["default-active"]);
    };
  }

};