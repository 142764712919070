import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./utils/flexible";

import * as Elicons from "@element-plus/icons-vue";
//引入Elmessage和Elloading的css样式文件
import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/el-message.css";
import echarts from "echarts";
const app = createApp(App);
//全局注册elementplus icon
Object.keys(Elicons).forEach((key) => {
  app.component(key, Elicons[key]);
});

// 输入值必为number指令
app.directive("numbers", (_el, _binding) => {
  _el.addEventListener("input", (event) => {
    const inputValue = event.target.value;
    const numericValue = parseFloat(inputValue);
    if (isNaN(numericValue)) {
      event.target.value = "";
    } else {
      event.target.value = numericValue;
    }
  });
});

createApp(App).use(store).use(router).mount("#app");

// 解决ResizeObserver loop limit exceeded问题
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
