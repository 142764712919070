import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
import { toRaw } from "vue";
export default {
  name: 'Menu',
  props: {
    route: Object
  },

  setup(__props) {
    const props = __props; // 导航栏图标设置

    if (toRaw(toRaw(props).route).icon) {
      if (toRaw(toRaw(props).route).icon == 1) {
        toRaw(toRaw(props).route).img = require("../assets/iconNav/zhushuju.png");
      } else if (toRaw(toRaw(props).route).icon == 2) {
        toRaw(toRaw(props).route).img = require("../assets/iconNav/wenti.png");
      } else if (toRaw(toRaw(props).route).icon == 3) {
        toRaw(toRaw(props).route).img = require("../assets/iconNav/renwu.png");
      } else if (toRaw(toRaw(props).route).icon == 4) {
        toRaw(toRaw(props).route).img = require("../assets/iconNav/wuye.png");
      } else if (toRaw(toRaw(props).route).icon == 5) {
        toRaw(toRaw(props).route).img = require("../assets/iconNav/bianming.png");
      } else if (toRaw(toRaw(props).route).icon == 6) {
        toRaw(toRaw(props).route).img = require("../assets/iconNav/xitong.png");
      } else if (toRaw(toRaw(props).route).icon == 7) {
        toRaw(toRaw(props).route).img = require("../assets/iconNav/daijinquan.png");
      }
    }

    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");

      const _component_Menu = _resolveComponent("Menu", true);

      const _component_el_sub_menu = _resolveComponent("el-sub-menu");

      return props.route.children.length <= 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 0,
        index: props.route.path
      }, {
        default: _withCtx(() => [props.route.icon ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: props.route.img,
          alt: ""
        }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(props.route.title), 1)]),
        _: 1
      }, 8, ["index"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        index: props.route.path
      }, {
        title: _withCtx(() => [props.route.icon ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: props.route.img,
          alt: ""
        }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(props.route.title), 1)]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.route.children, item => {
          return _openBlock(), _createBlock(_component_Menu, {
            key: item.path,
            route: item
          }, null, 8, ["route"]);
        }), 128))]),
        _: 1
      }, 8, ["index"]));
    };
  }

};