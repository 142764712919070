import {
    get,
    post,
    put,
    dele,
    exl
} from "@/http/api";
const prefix = "/oauth";

/**
 *  后台用户登录 
 * @param {*  "userName":"",    "passWord":""} params 
 */
export const login = params => post(`${prefix}/backedLogin`, params)