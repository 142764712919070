import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";
import { ref } from "vue";
axios.defaults.baseURL = "/api";
// 超时时间 /10000毫秒
axios.defaults.timeout = 5 * 60 * 1000;
// 加载等待（1）
const loading = ref(false); // loading
// 拦截器（中间件）
axios.interceptors.request.use(
  (config) => {
    // 加载等待（2）
    loading.value = ElLoading.service({
      lock: true,
      text: "加载中",
      background: "rgba(0, 0, 0, 0.7)",
    });
    // 路由守卫
    // 每次发送请求之前判断是否存在token，如果存在，
    // 则统一在HTTP请求的header都加上token，不用每次获取，
    // 即使本地token在，也有可能token是过期的，
    // 所以在响应拦截器中要对返回状态进行判断
    const jti = sessionStorage.getItem("jti");
    jti && (config.headers.jti = jti);

    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

axios.interceptors.response.use(
  // 请求成功
  (res) => {
    // 加载等待（3）
    if (loading) {
      loading.value.close();
    }
    return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
  },
  // 请求失败
  (error) => {
    if (loading) {
      loading.value.close();
    }
    if (error.response.status == 401) {
      ElMessage({
        appendTo: "#app",
        showClose: true,
        message: "登陆超时，请重新登陆",
        type: "error",
      });
    } else {
      ElMessage({
        appendTo: "#app",
        showClose: true,
        message: "请求失败",
        type: "error",
      });
    }
    const { response } = error;
    if (response) {
      // 请求已发出，但不在2XX范围内
      // errorHandle(response.status, response.data.message)
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时,更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据,会在断网组件中说明
      StorageEvent.commit("changeNetwork", false);
    }
  }
);

export default axios;
