import axios from "./http";
import { ElMessage } from "element-plus";

export const get = function (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getBolb = function (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        url,
        {
          params: params,
        },
        "blob"
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const post = function (url, data) {
  return new Promise((resolve, reject) => {
    //(data);
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.flag) {
          ElMessage({
            appendTo: "#app",
            showClose: true,
            message: res.data.message,
            type: "success",
          });
        } else {
          ElMessage({
            appendTo: "#app",
            showClose: true,
            message: res.data.message,
            type: "error",
          });
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

export const put = function (url, data) {
  return new Promise((resolve, reject) => {
    //(data);
    axios
      .put(url, data)
      .then((res) => {
        if (res.data.flag) {
          ElMessage.success(res.data.message);
        } else {
          ElMessage.error(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
export const deleS = function (url, data) {
  return new Promise((resolve, reject) => {
    //(data);
    axios
      .delete(url, data)
      .then((res) => {
        if (res.data.flag) {
          ElMessage.success(res.data.message);
        } else {
          ElMessage.error(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
export const dele = function (url, data) {
  return new Promise((resolve, reject) => {
    //(data);
    axios
      .delete(url, {
        params: data,
      })
      .then((res) => {
        if (res.data.flag) {
          ElMessage.success(res.data.message);
        } else {
          ElMessage.error(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
export const dow = function (url, params) {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      data: params,
      responseType: "blob",
    })
      .then((res) => {
        if (res.data.flag) {
          ElMessage.success(res.data.message);
        } else {
          ElMessage.error(res.data.message);
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const exl = function (url, params) {
  return new Promise((resolve, reject) => {
    axios(url, {
      params: params,
      responseType: "blob",
    })
      .then((res) => {
        if (res) {
          ElMessage.success("导出成功");
        } else {
          ElMessage.error("导出失败！");
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
